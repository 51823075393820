import { useState, useEffect } from 'react';
import CustomTreeItem from './CustomTreeItem';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';

import Box from '@mui/material/Box';

import FolderIcon from '../../../assets/icons/FolderIcon';

import styles from './styles';

import {
    useIntelyStorageFiles,
    fetchAndAddFoldersToIntelyStorage,
    setSelectedFolderAndGetFiles,
    useIntelyStorageSelectedFolder,
    resetFileFilters,
    resetFilteredFiles,
} from 'hooks/useMyFilesHook';

const FileTree = () => {
    const [width, setWidth] = useState(300);
    const [expandedIds, setExpandedIds] = useState([]);
    const intelyStorageFiles = useIntelyStorageFiles();
    const selectedFolder = useIntelyStorageSelectedFolder();
    const selectedFolderId = selectedFolder?.path && selectedFolder.path !== '/' ? [selectedFolder.path] : [];

    useEffect(() => {
        setExpandedIds((prevState) => {
            if (selectedFolderId.length) {
                return [...prevState, selectedFolderId[0]];
            } else {
                return prevState;
            }
        });
    }, [selectedFolder]);

    const handleMouseDown = (e) => {
        const initialX = e.clientX;

        const handleMouseMove = (e) => {
            const newWidth = width + (e.clientX - initialX);
            setWidth(newWidth);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleFolderSelectClick = (filePath) => {
        setSelectedFolderAndGetFiles(filePath);
        resetFilteredFiles();
        resetFileFilters();
    };

    const handleExpandedIconClick = (filePath) => {
        const isOpening = !expandedIds.includes(filePath);
        if (isOpening) {
            if (!intelyStorageFiles.get(filePath)) {
                fetchAndAddFoldersToIntelyStorage(filePath);
            }
        }
    };

    const renderTree = (path) =>
        (intelyStorageFiles.get(path) || []).map((file) => {
            const hasBeenLoaded = intelyStorageFiles.get(file.path),
                hasChildren = intelyStorageFiles.get(file.path)?.length;

            const loadNextNodes = () => {
                if (hasBeenLoaded) {
                    if (hasChildren) {
                        return renderTree(file.path);
                    } else {
                        return <div />;
                    }
                } else {
                    return <div />;
                }
            };

            return (
                <CustomTreeItem
                    key={file.path}
                    itemId={file.path}
                    label={file.name}
                    labelIcon={<FolderIcon />}
                    handleItemClick={handleFolderSelectClick}
                    handleExpansionClick={handleExpandedIconClick}
                >
                    {loadNextNodes()}
                </CustomTreeItem>
            );
        });

    return (
        <Box
            sx={styles.root({
                defaultWidth: width,
                minWidth: 150,
                maxWidth: 600,
            })}
        >
            <div className="container">
                <div className="fileTreeHeader">
                    <span onClick={() => handleFolderSelectClick('')}>All Folders</span>
                </div>
                <div className="treeContainer">
                    <SimpleTreeView
                        aria-label="Intely Storage File Navigation Tree"
                        selectedItems={selectedFolderId}
                        expandedItems={expandedIds}
                        onExpandedItemsChange={(_, itemIds) => setExpandedIds(itemIds)}
                    >
                        {renderTree('/')}
                    </SimpleTreeView>
                </div>
            </div>
            <span className="dragHandle" onMouseDown={handleMouseDown} />
        </Box>
    );
};

export default FileTree;

import PropTypes from 'prop-types';
import { Select, MenuItem, Chip } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useIsUserAdmin } from '../../../../../hooks/useUserHook';
import { ORGANIZATION_USER_STATUS_ACTIVE, ORGANIZATION_USER_STATUS_DISABLED } from './../../../../../config/constants';

const OrganizationUserStatusColumn = (props) => {
    const { organizationUser } = props,
        isUserAdmin = useIsUserAdmin(),
        isStatusActive = organizationUser.activatedAt ? true : false;

    const adminOptions = [
        { value: ORGANIZATION_USER_STATUS_ACTIVE, label: 'Active' },
        { value: ORGANIZATION_USER_STATUS_DISABLED, label: 'In-Active' },
    ];

    let renderStatus;
    if (isStatusActive) {
        renderStatus = (
            <Chip
                style={{
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    backgroundColor: '#DAFEF1',
                }}
                label="Active"
            />
        );
    } else if (!isStatusActive) {
        renderStatus = (
            <Chip
                style={{
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    backgroundColor: '#FFE2D1',
                }}
                label="In-Active"
            />
        );
    } else {
        renderStatus = null;
    }

    const theme = createTheme({
        components: {
            MuiSelect: {
                styleOverrides: {
                    borderWidth: 0,
                    root: {
                        borderWidth: 0,
                        color: '#333333',
                        backgroundColor: '#FFFFFF',
                        boxShadow: 'none',
                        fontSize: 14,
                        fontWeight: 400,
                    },
                },
            },
        },
    });

    const renderSelectorganizationUserStatus = (
        <ThemeProvider theme={theme}>
            <Select
                labelId="Status"
                id="status"
                fullWidth
                variant="outlined"
                label=""
                onChange={() => {}}
                value={isStatusActive}
            >
                {adminOptions &&
                    adminOptions?.map((item, index) => {
                        return (
                            <MenuItem
                                key={'status-' + index}
                                selected={item.value === isStatusActive}
                                value={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        );
                    })}
            </Select>
        </ThemeProvider>
    );

    return <div style={{ width: '100%' }}>{isUserAdmin ? renderSelectorganizationUserStatus : renderStatus}</div>;
};

OrganizationUserStatusColumn.propTypes = {
    organizationUser: PropTypes.object.isRequired,
};

export default OrganizationUserStatusColumn;

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import { SimpleTreeView } from '@mui/x-tree-view';
import CustomTreeItem from 'components/FileStorage/FileTree/CustomTreeItem';

import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BarLoader from 'react-spinners/BarLoader';
import FolderIcon from 'assets/icons/FolderIcon';

import { useIntelyStorageFiles, fetchAndAddFoldersToIntelyStorage } from 'hooks/useMyFilesHook';

const NewFolderInput = (props) => {
    const { newFolderName, onNewFolderNameChange, addNewFolder, cancel, error } = props;
    return (
        <div style={{ display: 'flex', flexDirection: 'row', columnGap: '6px', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                    id="new-folder-name"
                    label={null}
                    placeholder="New Folder Name"
                    type="text"
                    inputRef={(input) => input && input.focus()}
                    value={newFolderName}
                    onChange={(e) => onNewFolderNameChange(e.target.value)}
                    sx={{
                        '& .MuiInputBase-input': {
                            backgroundColor: 'white !important',
                            fontSize: '14px',
                            padding: '8px',
                        },
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            addNewFolder();
                        }
                    }}
                    error={!!error.length}
                />
                {error.length ? <FormHelperText error>{error}</FormHelperText> : null}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <IconButton sx={{ fontSize: '18px', color: '#87d857' }} onClick={addNewFolder}>
                    <CheckIcon />
                </IconButton>
                <IconButton sx={{ fontSize: '18px', color: '#d85757' }} onClick={cancel}>
                    <ClearIcon />
                </IconButton>
            </div>
        </div>
    );
};

NewFolderInput.propTypes = {
    newFolderName: PropTypes.string,
    onNewFolderNameChange: PropTypes.func,
    addNewFolder: PropTypes.func,
    cancel: PropTypes.func,
    error: PropTypes.string,
};

const FileTree = (props) => {
    const { selectedPath, onFolderClick, newFolder, onNewFolderNameChange, addNewFolder, cancel, isModalOpen } = props;
    const [expandedIds, setExpandedIds] = useState(['/']);
    const intelyStorageFiles = useIntelyStorageFiles();

    useEffect(() => {
        if (isModalOpen) {
            setExpandedIds(['/']);
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (newFolder.open && !expandedIds.includes(selectedPath)) {
            fetchAndAddFoldersToIntelyStorage(selectedPath);
            setExpandedIds((prevState) => [...prevState, selectedPath]);
        }
    }, [newFolder.open]);

    const handleExpandedIconClick = (filePath) => {
        if (!intelyStorageFiles.get(filePath)) {
            fetchAndAddFoldersToIntelyStorage(filePath === '/' ? '' : filePath);
        }
    };

    const handleAddNewFolder = () => {
        addNewFolder();
        // setExpandedIds((prevState) => [...prevState, selectedPath]);
    };

    const renderTree = (path) => {
        const folders = (intelyStorageFiles.get(path) || []).map((file) => {
            const hasBeenLoaded = intelyStorageFiles.get(file.path),
                hasChildren =
                    intelyStorageFiles.get(file.path)?.length || (selectedPath === file.path && newFolder.open);

            const loadNextNodes = () => {
                if (hasBeenLoaded) {
                    if (hasChildren) {
                        return renderTree(file.path);
                    } else {
                        return <div />;
                    }
                } else {
                    return (
                        <CustomTreeItem
                            key={`${file.id}-loading`}
                            itemId={`${file.id}-loading`}
                            CustomComponent={
                                <div style={{ display: 'flex', flexDirection: 'row', columnGap: '4px' }}>
                                    <AutorenewIcon />
                                    <BarLoader color="#3a70d4" />
                                </div>
                            }
                        />
                    );
                }
            };

            return (
                <CustomTreeItem
                    key={file.path}
                    itemId={file.path}
                    label={file.name}
                    labelIcon={<FolderIcon />}
                    handleItemClick={onFolderClick}
                    handleExpansionClick={handleExpandedIconClick}
                >
                    {loadNextNodes()}
                </CustomTreeItem>
            );
        });

        if (newFolder.open && path === selectedPath) {
            folders.unshift(
                <CustomTreeItem
                    key="new-folder"
                    itemId="new-folder"
                    CustomComponent={
                        <NewFolderInput
                            newFolderName={newFolder.name}
                            onNewFolderNameChange={onNewFolderNameChange}
                            addNewFolder={handleAddNewFolder}
                            cancel={cancel}
                            error={newFolder.error}
                        />
                    }
                >
                    <div />
                </CustomTreeItem>,
            );
        }

        return folders;
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <SimpleTreeView
                aria-label="Intely Storage Modal File Navigation Tree"
                selectedItems={selectedPath}
                expandedItems={expandedIds}
                onExpandedItemsChange={(_, itemIds) => setExpandedIds(itemIds)}
            >
                <CustomTreeItem
                    key="/"
                    itemId="/"
                    label="All Folders"
                    labelIcon={<FolderIcon />}
                    handleItemClick={onFolderClick}
                    handleExpansionClick={handleExpandedIconClick}
                >
                    {renderTree('/')}
                </CustomTreeItem>
            </SimpleTreeView>
        </Box>
    );
};

FileTree.propTypes = {
    selectedPath: PropTypes.string,
    onFolderClick: PropTypes.func.isRequired,
    newFolder: PropTypes.object,
    onNewFolderNameChange: PropTypes.func,
    addNewFolder: PropTypes.func,
    cancel: PropTypes.func,
    isModalOpen: PropTypes.bool,
};

export default FileTree;

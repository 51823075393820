import { authorizedAxiosInstance } from '../axiosInstances';
import { getGatewayUrl } from './envUtils';
import { showSnackBarSuccessNotification, showSnackBarErrorNotification } from './snackBarNotificationUtils';

/**
 * Fetches files in intely storage directory.
 * @param {string} organizationId
 * @param {string} directory
 * @param {object} options
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getFilesInDirectory = async (organizationId, directory, options = {}) => {
    return authorizedAxiosInstance
        .get(
            getGatewayUrl(
                `/organization/${organizationId}/storage/directory/files?directoryPath=${encodeURIComponent(
                    directory,
                )}&options=${encodeURIComponent(JSON.stringify(options))}`,
            ),
        )
        .then((response) => response.data)
        .catch((error) => {
            showSnackBarErrorNotification(error?.response?.data?.error || 'Error fetching files');
            throw error;
        });
};

/**
 * Adds a new folder to intely storage.
 * @param {string} organizationId
 * @param {string} directory
 * @param {string} fileName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addNewFolder = async (organizationId, directory, fileName) => {
    return authorizedAxiosInstance
        .post(getGatewayUrl(`/organization/${organizationId}/storage/directory`), {
            directoryPath: `${directory}/${fileName}`,
            overwrite: false,
        })
        .then((response) => {
            showSnackBarSuccessNotification('Folder created successfully');
            return response.data;
        })
        .catch((error) => {
            showSnackBarErrorNotification(error?.response?.data?.error || 'Error creating folder');
            throw error;
        });
};

/**
 * Renames a folder/file in intely storage.
 * @param {string} organizationId
 * @param {string} directory
 * @param {string} fileName
 * @param {string} newFileName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const renameFile = async (organizationId, path, newFileName) => {
    return authorizedAxiosInstance
        .patch(getGatewayUrl(`/organization/${organizationId}/storage/file/rename`), {
            filePath: path,
            newFileName,
            overwrite: false,
        })
        .then((response) => {
            showSnackBarSuccessNotification('File renamed successfully');
            return response.data;
        })
        .catch((error) => {
            showSnackBarErrorNotification(error?.response?.data?.error || 'Error renaming file');
            throw error;
        });
};

/**
 * Moves a folder/file in intely storage.
 * @param {string} organizationId
 * @param {string} sourcePath
 * @param {string} destinationPath
 * @returns {<Promise<AxiosResponse<any>>}
 */
export const moveFile = async (organizationId, sourcePath, destinationPath) => {
    return authorizedAxiosInstance
        .patch(getGatewayUrl(`/organization/${organizationId}/storage/file/move`), {
            filePath: sourcePath,
            newFilePath: destinationPath,
            overwrite: false,
        })
        .then((response) => {
            showSnackBarSuccessNotification('File moved successfully');
            return response.data;
        })
        .catch((error) => {
            showSnackBarErrorNotification(error?.response?.data?.error || 'Error moving file');
            throw error;
        });
};

/**
 * Copies a folder/file in intely storage.
 * @param {string} organizationId
 * @param {string} sourcePath
 * @param {string} destinationPath
 * @returns {<Promise<AxiosResponse<any>>}
 */
export const copyFile = async (organizationId, sourcePath, destinationPath) => {
    return authorizedAxiosInstance
        .post(getGatewayUrl(`/organization/${organizationId}/storage/file/copy`), {
            filePath: sourcePath,
            destinationFilePath: destinationPath,
            overwrite: false,
        })
        .then((response) => {
            showSnackBarSuccessNotification('File copied successfully');
            return response.data;
        })
        .catch((error) => {
            showSnackBarErrorNotification(error?.response?.data?.error || 'Error copying file');
            throw error;
        });
};

/**
 * Deletes a folder/file in intely storage.
 * @param {string} organizationId
 * @param {string} directory
 * @param {string} fileName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteFile = async (organizationId, path) => {
    return authorizedAxiosInstance
        .delete(getGatewayUrl(`/organization/${organizationId}/storage/directory`), {
            data: {
                directoryPath: path,
            },
        })
        .then((response) => {
            showSnackBarSuccessNotification('File deleted successfully');
            return response.data;
        })
        .catch((error) => {
            showSnackBarErrorNotification(error?.response?.data?.error || 'Error deleting file');
            throw error;
        });
};

/**
 * Gets file download URL
 * @param {string} organizationId
 * @param {string} filePath
 * @param {boolean} requiresAuth
 * @param {number} numberOfDownloads
 * @param {number|string} expiresAt
 * @returns {Promise<AxiosResponse<any>>}
 */
export const requestFileDownloadURL = async (
    organizationId,
    filePath,
    requiresAuth = false,
    numberOfDownloads = 1,
    expiresAt,
) => {
    return authorizedAxiosInstance
        .post(getGatewayUrl(`/organization/${organizationId}/storage/file/download`), {
            filePath,
            requiresAuth,
            numberOfDownloads,
            expiresAt,
        })
        .then((response) => response.data)
        .catch((error) => {
            showSnackBarErrorNotification(error?.response?.data?.error || 'Error fetching download token');
            throw error;
        });
};

/**
 * Uploads file using URL to intely storage.
 * @param {string} organizationId
 * @param {string} directory
 * @param {string} url
 * @param {string} fileName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const uploadFileUsingURL = async (organizationId, directory, url, fileName) => {
    return authorizedAxiosInstance
        .post(getGatewayUrl(`/organization/${organizationId}/storage/file/upload/url`), {
            directory: directory,
            url: url,
            overwrite: false,
            fileName: fileName,
        })
        .then((response) => {
            showSnackBarSuccessNotification('File uploaded successfully');
            return response.data;
        })
        .catch((error) => {
            showSnackBarErrorNotification(error?.response?.data?.error || 'Error uploading file');
            throw error;
        });
};

/**
 * Uploads file to intely storage.
 * @param {string} organizationId
 * @param {string} directory
 * @param {File} file
 * @param {string} fileName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const uploadFile = async (organizationId, directory, file, fileName) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('directory', directory === '/' ? '' : directory);
    formData.append('fileName', fileName.length ? fileName : file.name);
    formData.append('overwrite', false);

    return authorizedAxiosInstance
        .post(getGatewayUrl(`/organization/${organizationId}/storage/file/upload/binary`), formData)
        .then((response) => {
            showSnackBarSuccessNotification('File uploaded successfully');
            return response.data;
        })
        .catch((error) => {
            showSnackBarErrorNotification(error?.response?.data?.error || 'Error uploading file');
            throw error;
        });
};

/**
 * Start multi-part upload file to intely storage.
 * @param {string} organizationId
 * @returns {Promise<AxiosResponse<any>>} - Returns the uploadId
 */
export const startMultipartUpload = async (organizationId) => {
    return authorizedAxiosInstance
        .post(getGatewayUrl(`/organization/${organizationId}/storage/file/upload/multipart/start`))
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

/**
 * Uploads multi-part file to intely storage.
 * @param {string} organizationId
 * @param {string} uploadId
 * @param {File} file
 * @param {number} partNumber
 * @returns {Promise<AxiosResponse<any>>}
 */
export const uploadMultipartFile = async (organizationId, uploadId, file, partNumber) => {
    const formData = new FormData();
    formData.append('part', file);
    formData.append('partNumber', partNumber);

    return authorizedAxiosInstance
        .post(getGatewayUrl(`/organization/${organizationId}/storage/file/upload/multipart/${uploadId}/part`), formData)
        .then((response) => response.data)
        .catch((error) => {
            showSnackBarErrorNotification('Error uploading file');
            throw error;
        });
};

/**
 *
 * @param {string} organizationId
 * @param {string} uploadId
 * @param {number} numberOfParts
 * @param {string} directory
 * @param {string} fileName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const completeMultipartUpload = async (organizationId, uploadId, numberOfParts, directory, fileName) => {
    return authorizedAxiosInstance
        .post(getGatewayUrl(`/organization/${organizationId}/storage/file/upload/multipart/${uploadId}/complete`), {
            numberOfParts,
            directory: directory === '/' ? '' : directory,
            fileName,
            overwrite: false,
        })
        .then((response) => {
            showSnackBarSuccessNotification('File uploaded successfully');
            return response.data;
        })
        .catch((error) => {
            showSnackBarErrorNotification('Error uploading file');
            throw error;
        });
};

import { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import DescriptionIcon from '@mui/icons-material/Description';

import { descriptionTableColumnData, modifiedAtTableColumnData, modifiedByTableColumnData } from 'utils/tableUtils';
import {
    openCopyModal,
    openDeleteModal,
    openMoveModal,
    openRenameModal,
    setSelectedFolderAndGetFiles,
} from 'hooks/useMyFilesHook';
import { requestFileDownloadURL } from 'utils/intelyStorageUtils';
import { getCurrentOrganizationId } from 'utils/loginUtils';

const TableColumns = () => {
    return [
        descriptionTableColumnData({
            name: 'Name',
            fieldName: 'name',
            tooltipProps: false,
            isSortable: true,
            grow: 1,
            renderCallback: (row) => {
                const containerStyle = {
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '6px',
                        alignItems: 'center',
                    },
                    iconStyle = { fontSize: '20px', color: 'black' },
                    icon = row.isDirectory ? (
                        <FolderOutlinedIcon style={iconStyle} />
                    ) : (
                        <DescriptionIcon style={iconStyle} />
                    );

                if (row.isDirectory) {
                    containerStyle.cursor = 'pointer';
                    containerStyle['&:hover'] = {
                        textDecoration: 'underline',
                    };
                }

                return (
                    <Box
                        sx={containerStyle}
                        onClick={() => {
                            if (row.isDirectory) {
                                setSelectedFolderAndGetFiles(row.path);
                            }
                        }}
                    >
                        {icon}
                        <Typography style={{ fontSize: '13px', color: 'black' }}>{row.name}</Typography>
                    </Box>
                );
            },
        }),
        modifiedAtTableColumnData({
            name: 'Modified Date',
            fieldName: 'modifiedAt',
            grow: 0.3,
        }),
        modifiedByTableColumnData({
            name: 'Modified By',
            fieldName: 'modifiedBy',
            grow: 0.5,
        }),
        descriptionTableColumnData({
            name: 'Actions',
            fieldName: '',
            tooltipProps: false,
            isSortable: false,
            grow: 0.2,
            renderCallback: (row) => {
                const [anchorEl, setAnchorEl] = useState(null),
                    isActionMenuOpen = Boolean(anchorEl);

                const handleActionClick = (e) => {
                    setAnchorEl(e.currentTarget);
                };

                const handleMenuClose = () => {
                    setAnchorEl(null);
                };

                const handleRename = () => {
                    openRenameModal(row.path, row.isDirectory);
                    handleMenuClose();
                };

                const handleDelete = () => {
                    openDeleteModal(row.path, row.isDirectory);
                    handleMenuClose();
                };

                const handleMove = () => {
                    openMoveModal(row.path, row.isDirectory);
                    handleMenuClose();
                };

                const handleCopy = () => {
                    openCopyModal(row.path, row);
                    handleMenuClose();
                };

                const handleDownload = async () => {
                    try {
                        const response = await requestFileDownloadURL(getCurrentOrganizationId(), row.path);
                        const url = response.url;
                        const tab = window.open(url);

                        // Prevent error if user has popups blocked
                        if (tab) {
                            setTimeout(() => {
                                tab.close();
                            }, 1000);
                        }
                    } catch (e) {
                    } finally {
                        handleMenuClose();
                    }
                };

                return (
                    <>
                        <IconButton
                            key="file-actions"
                            aria-label="file-actions"
                            aria-controls="actions-menu"
                            aria-haspopup="true"
                            onClick={handleActionClick}
                            style={{
                                borderRadius: '4px',
                                width: '36px',
                                backgroundColor: '#F2F2F2',
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="file-actions-menu"
                            anchorEl={anchorEl}
                            open={isActionMenuOpen}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleDelete}>Delete</MenuItem>
                            {row.isDirectory ? null : <MenuItem onClick={handleDownload}>Download</MenuItem>}
                            <MenuItem onClick={handleRename}>Rename</MenuItem>
                            <MenuItem onClick={handleMove}>Move to</MenuItem>
                            <MenuItem onClick={handleCopy}>Copy to</MenuItem>
                        </Menu>
                    </>
                );
            },
        }),
    ];
};

export default TableColumns;

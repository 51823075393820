import { memo } from 'react';

import TableContainer from '../../Containers/TableContainer';
import TableColumns from './TableColumns';

import AppLoader from 'components/Loaders/AppLoader';

import {
    useIntelyStorageSelectedFolder,
    useNewlyAddedFile,
    useFilteredFiles,
    useDataTableLoading,
} from 'hooks/useMyFilesHook';

const IntelyStorageFilesTable = () => {
    const selectedFolder = useIntelyStorageSelectedFolder(),
        newlyAddedFile = useNewlyAddedFile(),
        filteredFiles = useFilteredFiles(),
        isLoading = useDataTableLoading();

    if (isLoading) {
        return <AppLoader />;
    } else {
        return (
            <TableContainer
                data={filteredFiles.filtered ? filteredFiles.files : selectedFolder.files}
                loading={false}
                columns={TableColumns({})}
                emptyDataText="No files in selected folder."
                style={{
                    border: '1px solid #E0E0E0',
                    borderLeft: 'none',
                    borderRight: 'none',
                    height: '100%',
                    overflowY: 'auto',
                }}
                pagination={false}
                updatedRowId={newlyAddedFile}
                idOverride="path"
                defaultSortFieldId={null}
            />
        );
    }
};

export default memo(IntelyStorageFilesTable);

import PropTypes from 'prop-types';
import PasswordField from '../PasswordField';

const PasswordConfirmationField = (props) => {
    const { passwordRef, passwordLabel = 'New password' } = props;

    /**
     * Validate password confirmation.
     */
    const validatePasswordConfirmation = (value) => {
        if (value !== passwordRef.current.value) {
            return {
                error: true,
                helperText: `${passwordLabel} confirmation is incorrect.`,
            };
        }
        return true;
    };

    const validateConfirmPasswordProp = {
        customValidator: validatePasswordConfirmation,
    };

    return (
        <PasswordField
            name="confirmPassword"
            label="Confirm New Password"
            placeholder="Confirm New Password"
            validate={validateConfirmPasswordProp}
            {...props}
        />
    );
};

PasswordConfirmationField.propTypes = {
    passwordRef: PropTypes.object.isRequired,
    passwordLabel: PropTypes.string,
};

export default PasswordConfirmationField;

import { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Cross from '../../../../assets/icons/Cross';
import {
    setSelectedOrganizationUserData,
    updateOrganizationUsersOptimistically,
    useSelectedOrganizationUserData,
} from '../../../../hooks/useOrganizationUsersHook';
import { MODAL_ACTIONS, setModalState } from '../../../../hooks/useModalStateHook';
import { deleteOrganizationUser } from '../../../../utils/organizationUtils';
import {
    showSnackBarErrorNotification,
    showSnackBarSuccessNotification,
} from '../../../../utils/snackBarNotificationUtils';
import {
    useIsDeleteUserConfirmationModalApproved,
    setConfirmationModalTitle,
    openDeleteUserConfirmationModal,
    clearConfirmationModalState,
} from '../../../../hooks/useModalHook';
import { getCurrentUserId } from 'hooks/useUserHook';
import { logout } from 'utils/loginUtils';
import useSelectedOrganization from 'hooks/useSelectedOrganizationHook';

const OrganizationUserTableDropdownActions = ({ organizationUser }) => {
    const selectedOrganizationUser = useSelectedOrganizationUserData();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const confirmationModalValue = useIsDeleteUserConfirmationModalApproved();
    const [currentSelectedOrganization] = useSelectedOrganization();

    const handleOrganizationSettingClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOrganizationSettingClose = () => {
        setAnchorEl(null);
    };

    const onOrganizationSettingMenuItem = () => {
        setAnchorEl(null);
        setSelectedOrganizationUserData(organizationUser);
        setModalState(MODAL_ACTIONS.UPDATE_ORGANIZATION_USER_MODAL)(true);
    };

    const onDeleteOrganizationUser = async () => {
        if (selectedOrganizationUser) {
            await deleteOrganizationUser(selectedOrganizationUser.organizationId, selectedOrganizationUser.userId)
                .then(async (data) => {
                    if (data) {
                        /**
                         * Optimistically delete the organization in organizations list
                         */
                        updateOrganizationUsersOptimistically('delete', data);
                        showSnackBarSuccessNotification('Organization user deleted Successfully');
                        // Logout the user if you delete self if user has Admin role
                        if (getCurrentUserId() === selectedOrganizationUser.userId) {
                            logout();
                        }
                    } else {
                        updateOrganizationUsersOptimistically('delete', []);
                    }
                })
                .catch((e) => {
                    showSnackBarErrorNotification(e.message);
                });
        }
    };

    const confirmDelete = () => {
        if (getCurrentUserId() === organizationUser.userId) return;

        setSelectedOrganizationUserData(organizationUser);
        setConfirmationModalTitle(
            `Are you sure you want to delete ${
                (organizationUser.firstName || '') + ' ' + (organizationUser.lastName || '')
            } user?`,
        );
        openDeleteUserConfirmationModal();
    };

    useEffect(() => {
        if (confirmationModalValue && selectedOrganizationUser?.userId === organizationUser?.userId) {
            clearConfirmationModalState();
            onDeleteOrganizationUser();
        }
    }, [confirmationModalValue]);

    return (
        <div>
            <Button
                id="organization-user-delete-button"
                onClick={confirmDelete}
                sx={{
                    padding: 0,
                    minWidth: '35px',
                    opacity:
                        getCurrentUserId() === organizationUser.userId || !currentSelectedOrganization.isAdmin
                            ? 0.5
                            : null,
                }}
                disabled={getCurrentUserId() === organizationUser.userId || !currentSelectedOrganization.isAdmin}
            >
                <Cross
                    style={{
                        padding: 5,
                        borderRadius: '50%',
                        backgroundColor: '#EB5757',
                        width: 23,
                        height: 23,
                        borderWidth: 1,
                    }}
                />
            </Button>
            <Button
                id="organization-user-button"
                aria-controls={open ? 'organization-user-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOrganizationSettingClick}
                disabled={!currentSelectedOrganization.isAdmin}
            >
                <SettingsIcon
                    style={{
                        color: '#3A70D4',
                        fontSize: '24px',
                    }}
                />
            </Button>
            <Menu
                id="organization-user-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleOrganizationSettingClose}
                MenuListProps={{
                    'aria-labelledby': 'organization-user-button',
                }}
            >
                {/* <MenuItem onClick={handleOrganizationSettingClose}>Reset password'</MenuItem> */}
                {/* <MenuItem onClick={handleOrganizationSettingClose}>Revoke Accounts</MenuItem> */}
                <MenuItem onClick={onOrganizationSettingMenuItem}>Edit User Settings</MenuItem>
            </Menu>
        </div>
    );
};

OrganizationUserTableDropdownActions.propTypes = {
    organizationUser: PropTypes.object.isRequired,
};

export default memo(OrganizationUserTableDropdownActions);
